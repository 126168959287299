import { graphql } from "gatsby"
import React from "react"
import Page from "../components/page"
import RequestDemoTemplate from "../components/request-demo-template"

const RequestDemoPage = ({ data: { footerData, requestDemoPageData } }) => {
  return (
    <Page headerData={{ frontmatter: {} }} footerData={footerData}>
      <RequestDemoTemplate data={requestDemoPageData.frontmatter} />
    </Page>
  )
}

export default RequestDemoPage

export const pageQuery = graphql`
  query RequestDemoQuery {
    ...PageFragment
    requestDemoPageData: markdownRemark(
      fileAbsolutePath: { regex: "/pages/request-demo.md/" }
    ) {
      frontmatter {
        title
      }
    }
  }
`
