import Grid from "@material-ui/core/Grid"
import MenuItem from "@material-ui/core/MenuItem"
import axios from "axios"
import { Field, Form, Formik } from "formik"
import { CheckboxWithLabel, TextField } from "formik-material-ui"
import { navigate } from "gatsby"
import React from "react"
import * as Yup from "yup"
import BigForm from "../components/big-form"
import CTAButton from "../components/CTAButton"
import PhoneMaskedInput from "../components/phone-masked-input"
import { PHONE_NUMBER_REGEX } from "../constants"

const RequestDemoSchema = Yup.object().shape({
  firstName: Yup.string().required("Required"),
  lastName: Yup.string().required("Required"),
  company: Yup.string().required("Required"),
  role: Yup.string(),
  email: Yup.string()
    .email("Invalid email")
    .required("Required"),
  phone: Yup.string().matches(
    PHONE_NUMBER_REGEX,
    "Invalid phone number. Please verify."
  ),
  companyType: Yup.string().required("Required"),
  weeklyTrends: Yup.boolean().required("Required"),
})

export const RequestDemoTemplate = ({ data: { title } }) => {
  return (
    <BigForm
      title={title}
      form={
        <Formik
          initialValues={{
            firstName: "",
            lastName: "",
            company: "",
            role: "",
            email: "",
            phone: "",
            companyType: "",
            weeklyTrends: false,
          }}
          validationSchema={RequestDemoSchema}
          onSubmit={(values, { setSubmitting }) => {
            axios
              .post("/api/request_demo", {
                first_name: values.firstName,
                last_name: values.lastName,
                role: values.role,
                company: values.company,
                email: values.email,
                phone: values.phone,
                company_type: values.companyType,
                get_trends: values.weeklyTrends,
              })
              .then(
                () => {
                  navigate("/thank-you")
                },
                () => {
                  setSubmitting(false)
                }
              )
          }}
        >
          {({ submitForm, isSubmitting }) => (
            <Form>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <Field
                    required
                    component={TextField}
                    name="firstName"
                    label="First Name"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Field
                    required
                    component={TextField}
                    name="lastName"
                    label="Last Name"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Field
                    required
                    component={TextField}
                    name="company"
                    label="Company Name"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Field
                    component={TextField}
                    name="role"
                    label="Role"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Field
                    required
                    component={TextField}
                    name="email"
                    type="email"
                    label="Email"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Field
                    component={TextField}
                    name="phone"
                    label="Phone number"
                    fullWidth
                    InputProps={{
                      inputComponent: PhoneMaskedInput,
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    required
                    component={TextField}
                    name="companyType"
                    select
                    label="Company type"
                    fullWidth
                  >
                    <MenuItem value="small-broker">Small Broker</MenuItem>
                    <MenuItem value="large-broker">Large Broker</MenuItem>
                    <MenuItem value="dealer">Dealer</MenuItem>
                    <MenuItem value="auction-house">Auction House</MenuItem>
                    <MenuItem value="carrier">Carrier</MenuItem>
                    <MenuItem value="owner-operator">Owner Operator</MenuItem>
                  </Field>
                </Grid>
                <Grid item xs={12}>
                  <Field
                    color="primary"
                    component={CheckboxWithLabel}
                    name="weeklyTrends"
                    type="checkbox"
                    Label={{ label: "Get weekly market and price trends" }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <CTAButton
                    variant="contained"
                    color="primary"
                    fullWidth
                    size="large"
                    disabled={isSubmitting}
                    onClick={submitForm}
                  >
                    Submit
                  </CTAButton>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      }
    />
  )
}

export default RequestDemoTemplate
